
<template>
    <object :data="Constants.ServerUrl + 'Checklist/print_pdf/' + IdChecklist" type="application/pdf" width="100%" :height="parentHeight">
        <p><a :href="Constants.ServerUrl + 'Checklist/print_pdf/' + IdChecklist"></a></p> 
    </object>                        
</template>

<script>
import settings from "@/backend/LocalSettings"
import BasePage from '@/pages/BasePage'
import Constants from '@/backend/Constants'
export default {
    name: "checklist_pdf",
    extends: BasePage,

    data(){
        return {
            Constants: Constants,
            IdChecklist: 0,   
            parentHeight: 1000,        
        }
    },
    methods: {
        genereaza() {  
            
            this.IdChecklist = this.$route.params.checklist
            console.log(this.IdChecklist)
        }
    },
    mounted() {
        this.genereaza()
    }
}
</script>

